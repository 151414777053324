<template>
  <div>
    <div class="mb-0">
      <Message
        ref="buttonMessage"
        :key="messageBtnKey"
        class="chat__input flex bg-white"
        :has-errors="hasError"
        @onTextChange="onTextChange"
      />
    </div>

    <div
      class="error-message height-fit-content my-3 text-center"
      v-if="errorMsg && errorMsg.length"
    >
      <p v-for="(errorObj, index) in errorMsg" :key="index">{{ errorObj }}</p>
    </div>

    <div class="mt-4">
      <BotMessageValidation />
    </div>

    <div class="flex justify-center mt-4">
      <vs-button
        class="with-margin-right"
        color="danger"
        type="flat"
        @click="onCancel"
        >{{
          lang.botMaker.botEditor.cancelMessage[languageSelected]
        }}</vs-button
      >

      <vs-button
        :disabled="hasError"
        class="bg-primary-gradient ml-4"
        type="filled"
        @click="addMessage()"
        v-if="!botMessageInEdit.isSaved"
      >
        <span
          v-if="!hasError && !botMessageInEdit.isSaved"
          v-text="lang.botMaker.botEditor.addMessage[languageSelected]"
        ></span>

        <span
          v-if="hasError && !botMessageInEdit.isSaved"
          v-text="lang.botMaker.botEditor.addingMessage[languageSelected]"
        ></span>
      </vs-button>

      <vs-button
        :disabled="hasError"
        class="bg-primary-gradient ml-4"
        type="filled"
        @click="modifyMessage()"
        v-if="botMessageInEdit.isSaved"
      >
        <span
          v-if="!hasError && botMessageInEdit.isSaved"
          v-text="lang.botMaker.botEditor.modifyMessage[languageSelected]"
        ></span>
      </vs-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { ButtonsMessageType } from '@/models/Messages/MessageTypes/ButtonsMessageType'
import botMessageValidationMixin from '@/mixins/botMessageValidationMixin'

export default {
  data() {
    return {
      errorMsg: [],
      buttonDisabled: false,
      hasError: false,
      messageBtnKey: new Date().toString()
    }
  },
  mixins: [botMessageValidationMixin],
  components: {
    Message: () => import('./buttonsMessage/Message'),
    BotMessageValidation: () => import('../userSays/BotMessageValidation.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'userMessageInEdit',
      'botMessageInEdit',
      'botMakerMessages',
      'botId',
      'activeChannelView'
    ])
  },
  watch: {
    '$store.state.botMaker.botMessageInEdit.id'() {
      this.messageBtnKey = new Date().toString()
    }
  },
  methods: {
    ...mapActions('botMaker', ['SAVE_BOT_MESSAGE', 'GET_DIALOGS']),
    ...mapMutations('botMaker', [
      'SET_ACTIVE_CHANNEL_VIEW',
      'UPDATE_MESSAGE',
      'RESET_BOT_MESSAGE'
    ]),
    onCancel() {
      this.$refs.buttonMessage.reset()
      //this.RESET_BOT_MESSAGE();
      // this.SET_ACTIVE_CHANNEL_VIEW(this.activeChannelView)
    },
    gT(item) {
      return this.lang.botMaker.botEditor.messageType.RcsCard.errors[item][
        this.languageSelected
      ]
    },
    showError() {
      const mField = this.lang.botMaker.botEditor.missingField[
        this.languageSelected
      ]
      this.errorMsg.push(`${mField} ${this.gT('text_field').toLowerCase()}`)
      this.hasError = true
    },
    showErrorBtns() {
      const mField = this.lang.botMaker.botEditor.missingField[
        this.languageSelected
      ]
      this.errorMsg.push(`${mField} ${this.gT('buttons').toLowerCase()}`)
      this.hasError = true
    },
    async addMessage() {
      this.errorMsg = []
      console.log('this.botMessageInEdit: ', this.botMessageInEdit)
      // return
      const type = this.botMessageInEdit.type
      if (type.name.length === 0 || type.name.length > 2000) {
        this.showError()
      }

      if (
        type.responseOptions == undefined ||
        type.responseOptions.length == 0
      ) {
        this.showErrorBtns()
      }

      if (this.hasError) {
        this.hasError = false
        return false
      }

      this.$vs.loading()
      const getDialogsId =
        this.botMakerMessages[0] && this.botMakerMessages[0].dialogSavedId
      const dialogSavedId = (this.userMessageInEdit && this.userMessageInEdit.dialogSavedId) ||
        (this.botMessageInEdit && this.botMessageInEdit.dialogSavedId)
      // on update image
      if (this.botMessageInEdit.isSaved) {
        await this.botMessageInEdit.type.save(this.botId)
      }

      const botDialogHandler = JSON.parse(JSON.stringify(this.botMessageInEdit))
      const botDialog = botDialogHandler.type

      this.botMessageInEdit.type = new ButtonsMessageType(
        botDialog.responseText ? botDialog.responseText : botDialog.name,
        botDialog.responseOptions,
        botDialog.platforms,
        botDialog._id,
        botDialog.responseId
      )

      // this.botMessageInEdit.type.platforms = ['all']

      await this.saveValidation()

      this.SAVE_BOT_MESSAGE(this.botMessageInEdit)
        .then(async response => {
          console.log('add msg ::')
          await this.GET_DIALOGS(dialogSavedId || getDialogsId)

          // RESET CONDS
          // this.RESET_BOT_MESSAGE()
          // this.SET_MESSAGE_IN_EDIT()
          // this.RESET_USER_MESSAGE()
          // this.RESET_CONDITIONS()

          this.SET_ACTIVE_CHANNEL_VIEW('all')
          this.messageBtnKey = new Date().toString()
          return response
        })
        .catch(error => {
          return error
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    async modifyMessage() {
      this.errorMsg = []

      const type = this.botMessageInEdit.type
      if (type.name.length === 0 || type.name.length > 2000) {
        this.showError()
      }

      if (
        type.responseOptions == undefined ||
        type.responseOptions.length == 0
      ) {
        this.showErrorBtns()
      }

      if (this.hasError) {
        this.hasError = false
        return false
      }

      this.$vs.loading()
      // const getDialogsId =
      //   this.botMakerMessages[0] && this.botMakerMessages[0].dialogSavedId

      // Estaria causando conflicto después del if?
      const botDialogHandler = JSON.parse(JSON.stringify(this.botMessageInEdit))
      const botDialog = botDialogHandler.type

      this.botMessageInEdit.type = new ButtonsMessageType(
        botDialog.responseText ? botDialog.responseText : botDialog.name,
        botDialog.responseOptions,
        botDialog.platforms,
        botDialog._id,
        botDialog.responseId
      )

      // on update image
      if (this.botMessageInEdit.isSaved) {
        await this.botMessageInEdit.type.save(this.botId)
      }

      this.botMessageInEdit.type.platforms = [this.activeChannelView]

      await this.saveValidation()

      this.SAVE_BOT_MESSAGE(this.botMessageInEdit)
        .then(async () => {
          console.log('add msg ::')
          this.UPDATE_MESSAGE(this.botMessageInEdit)

          this.SET_ACTIVE_CHANNEL_VIEW('all')
        })
        .catch(error => {
          return error
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    onTextChange(text) {
      this.botMessageInEdit.type.name = text
    }
  }
}
</script>
